import React from 'react';
import { CareerOutlookFact } from 'labxchange-client';
import { SanitizeConfigOptions, sanitizeUnsafeHTML } from 'elements/utils/sanitization';

interface CareerOutlookFactProps {
  cardNumber: number;
  fact: CareerOutlookFact;
}

const CareerOutlookFact: React.FC<CareerOutlookFactProps> = ({ cardNumber, fact }) => {
  return (
    <div className='fact-card'>
      <div className='fact-body'>
        <div className='content'>
            <div className='badge'>{`#${cardNumber}`}</div>
            <div
              className='description'
              dangerouslySetInnerHTML={{
                __html: sanitizeUnsafeHTML(fact.details, SanitizeConfigOptions.UnsafeHTMLSimple)
              }}
            />
        </div>
        <img className='header-image' src={fact.factImage}></img>
      </div>
      <div
        className='fact-footer'
        dangerouslySetInnerHTML={{
          __html: sanitizeUnsafeHTML(fact.sourceText, SanitizeConfigOptions.UnsafeHTMLSimple)
        }}
      />
    </div>
  );
};

export default CareerOutlookFact;
