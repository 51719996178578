// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CurriculumTaggingData,
    CurriculumTaggingDataFromJSON,
    CurriculumTaggingDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaginatedCurriculumTaggingData
 */
export interface PaginatedCurriculumTaggingData {
    /**
     * 
     * @type {number}
     * @memberof PaginatedCurriculumTaggingData
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedCurriculumTaggingData
     */
    next: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedCurriculumTaggingData
     */
    previous: string;
    /**
     * 
     * @type {CurriculumTaggingData}
     * @memberof PaginatedCurriculumTaggingData
     */
    results: CurriculumTaggingData;
}

export function PaginatedCurriculumTaggingDataFromJSON(json: any): PaginatedCurriculumTaggingData {
    return {
        'count': json['count'],
        'next': json['next'],
        'previous': json['previous'],
        'results': CurriculumTaggingDataFromJSON(json['results']),
    };
}

export function PaginatedCurriculumTaggingDataToJSON(value?: PaginatedCurriculumTaggingData): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': CurriculumTaggingDataToJSON(value.results),
    };
}


