import React from 'react';

import { Icon } from 'elements';
import { CareerQuote } from 'labxchange-client';
import { SanitizeConfigOptions, sanitizeUnsafeHTML } from 'elements/utils/sanitization';


interface QuoteProps {
  quote: CareerQuote;
  imageClass?: string;
  iconColor: string;
}

const Quote: React.FC<QuoteProps> = ({ quote, imageClass, iconColor }) => {
  if (!quote) {
    return null;
  }

  return (
    <div className='column info'>
      <div className='info-header'>
        <div className='info-icon'>
          <Icon name='quote-v2' fill={iconColor} />
        </div>
        <div className='info-text'>
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizeUnsafeHTML(quote.quote, SanitizeConfigOptions.UnsafeHTMLSimple)
            }}
          />”
        </div>
      </div>
      <div className='info-details'>
        <div className='info-avatar'>
          <img className={imageClass} src={quote.authorImage} alt='avatar' />
        </div>
        <div className='info-person'>
          <span className='person-name'>{quote.authorName}</span>
          <span className='person-role'> - {quote.authorDesignation}</span>
        </div>
      </div>
    </div>
  );
};

export default Quote;
